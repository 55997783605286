/*eslint-disable*/
import React, { useEffect, useMemo } from "react";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { cloneDeep, debounce } from "lodash";
import { FixedSizeList as List } from "react-window";
import FormulaColorGenerator from "./FormulaColorGenerator";

const KFPCalculator = (props) => {
  const {
    kpfRow,
    compareData,
    setCompareData,
    checkList,
    generalData,
    kfpList,
    searchList,
    serviceLines,
    setServiceLines
  } = props;

  useEffect(() => {
    setServiceLines([]);
  }, []);

  let uniqueList = [];
  let arr = [];

  const debouncedFilterList = useMemo(() =>
    debounce((inputValue) => {
      const regOperator = /[\+\-\*\/]/;
      let substring = '';
      const query = inputValue;

      if (typeof query !== 'undefined') {
        substring = query.split(regOperator).pop();
      }

      if (substring && substring.length >= 3) {
        const lowerSubstring = substring.toLowerCase();
        const newList = searchList.reduce((acc, sec) => {
          if (sec.number.toLowerCase().startsWith(lowerSubstring.slice(0, 2))) {
            if (sec.kfpSpecs) {
              sec.kfpSpecs.forEach((spec) => {
                if (spec.number.toLowerCase().startsWith(lowerSubstring.slice(0, 3))) {
                  if (spec.kfpServiceLines) {
                    const matchedServices = spec.kfpServiceLines.filter((service) =>
                      service.toLowerCase().startsWith(lowerSubstring)
                    );
                    acc.push(...matchedServices);
                  }
                }
              });
            }
          }
          return acc;
        }, []);

        setServiceLines(newList);
      } else {
        setServiceLines([]);
      }
    }, 300),
    [searchList, setServiceLines]
  );

  const Row = ({ index, style, data }) => (
    <div style={style} className="cursor-pointer" data-test="click2" onClick={() => { newFormula(data[index]); }}>
      {data[index]}
    </div>
  );

  const handleChange = (e) => {
    const value = e.target.value;
    const temp = cloneDeep(compareData);
    temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula = value;
    setCompareData(temp);
    debouncedFilterList(value);
  };

  const newFormula = (e) => {
    const temp = cloneDeep(compareData);
    const data = temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula
    let myString = '';

    const operators = ["+", "-", "*", "/"];
    let lastOperator = '';
    const hasOperator = operators.some((operator) => data.includes(operator)); // contains operator/not

    if (hasOperator) {
      lastOperator = data.match(/[+\-*/]/g).pop(); // get last operator

      const splitStr = data.split(""); // convert string to array of characters
      let lastIndex = -1;

      for (let i = splitStr.length - 1; i >= 0; i--) {
        if (splitStr[i] === lastOperator) {
          lastIndex = i;
          break;
        }
      }
      myString = data.substring(0, lastIndex); // get string before the operators
    }

    temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula = myString + lastOperator + e;
    setCompareData(temp);
    setServiceLines([]);
  };

  const getRoundedValue = (value) => {
    if (typeof value === 'undefined') {
      return '';
    }
    return value.toLocaleString("en-IN", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  return (
    <div className="me-2">
      <div>Click to choose KFP</div>
      <div className="text-md mr-2 w-full fixed_header mt-3">
        <div className="d-flex p-2 me-4 bg-gray-cq">
          <div className="w-1/5">Sec#</div>
          <div className="w-1/5">KP Category</div>
          <div className="w-1/5">Code</div>
          <div className="w-1/5">Description</div>
          <div className="w-1/5">UOM</div>
        </div>
        {kfpList?.length > 0 &&
          <div className="kfp-list-scroll me-4">
            {kfpList?.map((i, index) => {
              const m = compareData[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula
              const showImage = m ? m.includes(i.code) : false;
              if (m) {
                const n = cloneDeep(checkList);
                n.map((x, inx) => {
                  x.specDataList.map((y, iny) => {
                    y.serviceLineList.map((z, inz) => {
                      if ((m.toLowerCase()).includes((z?.number).toLowerCase())) {
                        arr.push(compareData[kpfRow.i].sectionQuoteList[inx].specList[iny].serviceLineList[inz]);
                      }
                    });
                  });
                });
                uniqueList = Array.from(new Set(arr, ...uniqueList));
              }
              return (
                <div
                  className="mt-2 cursor-pointer border-kpf d-flex p-2"
                  key={index}
                  data-test="click1"
                  onClick={() => {
                    const temp = cloneDeep(compareData);
                    let val = temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula;
                    if (val === null || !val) {
                      val = i?.code;
                    } else {
                      val = val.concat("", i?.code);
                    }
                    temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula = val;
                    setCompareData(temp);
                  }}
                >
                  <div className={kfpList?.length !== index + 1 ? "w-1/5 ms-1" : "border-none w-1/5 ms-1"}>{i?.specNo}</div>
                  <div className={kfpList?.length !== index + 1 ? "w-1/5 ms-1" : "border-none w-1/5 ms-1"}>{i?.kpCategory}</div>
                  <div className={kfpList?.length !== index + 1 ? "w-1/5 ms-1" : "border-none w-1/5 ms-1"}>{i?.code}</div>
                  <div data-tooltip-id="project-nonspec" data-tooltip-content={i?.description ? i?.description : ''} className={kfpList?.length !== index + 1 ? "timeline-text-wrap des-w w-1/5 ms-1" : "border-none timeline-text-wrap des-w w-1/5 ms-1"}>
                    <span>{i?.description}</span></div>
                  <div className={kfpList?.length !== index + 1 ? "w-1/5 gap-4 ms-1" : "border-none gap-4 w-1/5 ms-1"}>
                    {i?.uom}
                    {showImage && <span className="icon-check-rnd tick-green font-20 ms-4 mt-1" />}
                  </div>
                </div>
              );
            })}
          </div>
        }
      </div>
      {kfpList?.length === 0 &&
        <div className="d-flex justify-content-center mt-4">
          No data found
        </div>
      }
      <div className="mt-4">
        <div className="text-xl">Modified Formula</div>
        <input
          className="w-full mt-3 text-box large"
          type="text"
          data-test="change1"
          onChange={handleChange}
          value={compareData[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula ?
            compareData[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula : ''}
        />
      </div>
      <div className="me-4 mt-2">
        <List
          className={serviceLines.length ? "suggestion-list-kfp" : "d-none"}
          height={serviceLines.length ? 400 : 0}
          itemCount={serviceLines.length}
          itemSize={35}
          itemData={serviceLines}
        >
          {Row}
        </List>
      </div>
      {/* {compareData[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula &&
        <FormulaColorGenerator
          formula={compareData[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula}
        />
      } */}
      <div className="mt-4 text-xl">
        <div>Value Inputs</div>
        {uniqueList?.length > 0 && uniqueList?.map((i, index) => {
          if (i?.id !== "00000000-0000-0000-0000-000000000000") {
            return (
              <div className="mt-1" key={index}>
                <div className="d-flex value-input font-bl">
                  <div className="w-full header-bg-kfp border-r">
                    <div className="mt-2 ms-3">{i?.description || i?.number}
                    </div>
                  </div>
                  <div className="w-full input-height-cq ms-2 mt-2">{i?.uom || i?.uomName}</div>
                </div>
              </div>
            );
          }
        })}
        <div className="mt-1">
          <div className="d-flex value-input font-bl">
            <div className="w-full header-bg-kfp border-r">
              <div className="mt-2 ms-3">Modified Total
              </div>
            </div>
            <div className="w-full input-height-cq ms-2 mt-2">
              {generalData?.currencySymbol}&nbsp;{getRoundedValue(compareData[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].ownerEstimate)}
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="project-nonspec" place="top" className="tooltip mt-3" />
    </div>
  );
}

export default KFPCalculator;

/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { cloneDeep, round, toNumber } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import KFPCalculator from './KFPCalculator';
import CQSidePanel from './CQSidePanel';
import ModalBox from '../../../../../common/ModalBox';
import { evaluate } from 'mathjs';
import helper from '../../../../../../utils/helper';

import arrowRight from './images/arrow-right.svg';
import alertLeft from './images/arrow-left.svg';
import plusCircle from './images/plus-blue.svg';
import attachment from './images/attachment.svg';
import showAlert from '../../../../../../utils/alert';
import CostCalculator from './CostCalculator';

const MappingData = (props) => {
  const {
    checkList, compareData, setCompareData, generalData, kfpList, SaveCQ, SaveComment, specCount,
    searchList, units, setCanSend, isTSI, closeModal, setCloseModal, kpfRow, setKPFRow, disableFields,
    projectDetails, repairStarted, canSend, setSelectedYard, addComment, setAddComment, commentValue,
    setCommentValue, selectModal, setSelectModal, postDOCostDetails, sidePanelData, setSidePanelData,
    applyAllModal, setApplyAllModal, setYardSelect, calculate, setCalculate, yardComment, setYardComment,
    sortedQuotes, setSortQuotes
  } = props;

  const { charValidation, formatNumber } = helper;
  const [commentClicked, setCommentClicked] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [showSideBarCost, setShowSideBarCost] = useState(false);
  const [docData, setDocData] = useState('');
  const [formula, setFormula] = useState('');
  const [serviceLines, setServiceLines] = useState([]);

  /* istanbul ignore next */
  const reload = (array = []) => {
    const CQ = array || compareData;
    CQ?.map((yard) => {
      let sumY = 0;
      let sumO = 0;
      yard?.sectionQuoteList?.map((sec) => {
        let yE_Sec = 0;
        let oE_Sec = 0;
        sec?.specList?.map((spec, ink) => {
          let yE_Spec = 0;
          let oE_Spec = 0;
          spec?.serviceLineList?.map((serv, inl) => {
            yE_Spec = yE_Spec + serv?.yardEstimate;
            if (spec?.specAssigned === 1) {
              oE_Spec = oE_Spec + serv?.ownerEstimate;
            }
            if (spec?.serviceLineList?.length === (inl + 1)) {
              spec.yardEstimate = yE_Spec;
              spec.ownerEstimate = oE_Spec;
              yE_Sec = yE_Sec + yE_Spec;
              oE_Sec = oE_Sec + oE_Spec;
            }
          });
          if (sec?.specList?.length === (ink + 1)) {
            sec.yardEstimate = yE_Sec;
            sec.ownerEstimate = oE_Sec;
            sumY = sumY + yE_Sec;
            sumO = sumO + oE_Sec;
            yard.yardTotal = sumY;
            yard.ownerEstimate = sumO;
          }
        });
      });
    });
    sortQuotes(compareData);
    setCalculate(false);
  };

  useEffect(() => {
    reload();
  }, [calculate]);

  const sortQuotes = (quotes) => {
    let sortArray = [];

    quotes?.forEach((i) => {
      const total = formatNumber(
        round(i.ownerEstimate) -
        round((i.ownerEstimate / 100) * toNumber(i.additionalDiscountPercentage)) -
        round(i?.additionalDiscount) +
        round(i?.ownerCost) +
        round(i?.deviationCost) +
        round(i?.offhireCost)
      );
      const object = {
        id: i.id,
        total: parseFloat(total.replace(/,/g, '')),
        isHighest: false,
        isLowest: false,
        isMedian: false
      };
      sortArray.push(object);
    });

    sortArray.sort((a, b) => a.total - b.total);
    const sortedArrayWithFlags = assignFlags(sortArray);
    setSortQuotes(sortedArrayWithFlags);
  };

  const assignFlags = (array) => {
    const len = array.length;

    if (len > 1) {
      array[0].isLowest = true;
      array[len - 1].isHighest = true;

      if (len % 2 === 0) {
        const mid1 = Math.floor(len / 2) - 1;
        const mid2 = Math.floor(len / 2);

        if (array[mid1].total < array[mid2].total) {
          array[mid1].isMedian = true;
        } else {
          array[mid2].isMedian = true;
        }
      } else {
        const mid = Math.floor(len / 2);
        array[mid].isMedian = true;
      }
    }
    return array;
  };

  const handleChange = (e, i, rfqId, selected, save, field, alertValue) => {
    const temp = cloneDeep(compareData);
    let value = 0;
    if (field === 'selectionComment') {
      if (e) temp[i][field] = e;
    } else {
      if (e.target && e.target.value) {
        if (field === 'additionalDiscount') {
          const regex = /^\d+(\.\d{0,2})?$/;
          if (regex.test(e.target.value) || e.target.value !== '') {
            let inputValue = e.target.value;
            inputValue = inputValue.replace(/[^\d.]/g, (match, index) => {
              if (match === '.') {
                return index === 0 || inputValue.indexOf('.') !== -1 ? '.' : '';
              }
              return '';
            });
            inputValue = inputValue.match(/^\d{0,5}?$/) ? inputValue : temp[i].additionalDiscount;
            temp[i].additionalDiscount = inputValue;
            value = inputValue;
          }
        } else {
          value = toNumber(charValidation(e.target.value.replace(/[^0-9]/g, '')));
          temp[i][field] = value;
        }
      } else {
        temp[i][field] = null;
      }
    }
    const data = {
      rfqId: rfqId,
      comment: field === 'selectionComment' ? e : temp[i].selectionComment,
      isSelected: selected,
      additionalDiscount: field === 'additionalDiscount' ? value : temp[i] ? temp[i].additionalDiscount : 0,
      additionalDiscountPercentage: temp[i] ? temp[i].additionalDiscountPercentage : 0,
    };
    setYardSelect(data);
    if (save) SaveComment(data, alertValue);
    setCompareData(temp);
    sortQuotes(temp);
  };

  const handleChangeRow = (e, i, j, k, l, field, isSave, type) => {
    const temp = cloneDeep(compareData);
    if (type === 'select') {
      units.map((m) => {
        if (e.target && e.target.value) {
          if (e.target.value === m.value) {
            temp[i].sectionQuoteList[j].specList[k].serviceLineList[l].uomId = m.value;
            temp[i].sectionQuoteList[j].specList[k].serviceLineList[l].uomName = m.label;
            temp[i].sectionQuoteList[j].specList[k].serviceLineList[l].unit = m.name;
          }
        }
      });
      setCompareData(temp);
    } else {
      let num = 0;
      if (field === 'discount') {
        if (e.target && e.target.value) {
          num = e.target.value.match(/^\d{0,3}(\.\d{0,2})?$/) ? e.target.value : temp[i].sectionQuoteList[j].specList[k].serviceLineList[l][field];
          if (num <= 100) {
            temp[i].sectionQuoteList[j].specList[k].serviceLineList[l][field] = num.toString();
          }
        }
        if (e.target.value === '') {
          temp[i].sectionQuoteList[j].specList[k].serviceLineList[l][field] = null;
        }
      } else {
        const regex = /^\d+(\.\d{0,2})?$/;
        if (regex.test(e.target.value) || e.target.value !== '') {
          let inputValue = e.target.value;
          inputValue = inputValue.replace(/[^\d.]/g, (match, index) => {
            if (match === '.') {
              return index === 0 || inputValue.indexOf('.') !== -1 ? '.' : '';
            }
            return '';
          });
          if (field === 'quantity')
            inputValue = inputValue.match(/^\d{0,6}(\.\d{0,2})?$/) ? inputValue : temp[i].sectionQuoteList[j].specList[k].serviceLineList[l][field];
          else inputValue = inputValue.match(/^\d{0,5}(\.\d{0,2})?$/) ? inputValue : temp[i].sectionQuoteList[j].specList[k].serviceLineList[l][field];
          temp[i].sectionQuoteList[j].specList[k].serviceLineList[l][field] = inputValue;
        }
        if (e.target.value === '') {
          temp[i].sectionQuoteList[j].specList[k].serviceLineList[l][field] = 0;
        }
      }
      const x = temp[i].sectionQuoteList[j].specList[k].serviceLineList[l].quantity;
      const y = temp[i].sectionQuoteList[j].specList[k].serviceLineList[l].unitPrice;
      const z = temp[i].sectionQuoteList[j].specList[k].serviceLineList[l].discount;
      const res = x * y - ((x * y) / 100) * z;
      temp[i].sectionQuoteList[j].specList[k].serviceLineList[l].yardEstimate = res;
      if (!temp[i].sectionQuoteList[j].specList[k].serviceLineList[l].formula && temp[i].sectionQuoteList[j].specList[k].specAssigned === 1) {
        temp[i].sectionQuoteList[j].specList[k].serviceLineList[l].ownerEstimate = res;
      }
      setCompareData(temp);
      sortQuotes(temp);
      reload(temp);
    }
    if (isSave) SaveCQ(compareData[i].sectionQuoteList[j].specList[k].serviceLineList[l], field === 'quantity' && compareData?.length > 1 ? true : false);
    if (temp[i].sectionQuoteList[j].specList[k].serviceLineList[l].isFromYard) setCalculate(true);
  };

  /* istanbul ignore next */
  const onClose = () => {
    setShowSideBar(false);
    setShowSideBarCost(false);
  };

  /* istanbul ignore next */
  const calculateFormula = (equation) => {
    const temp = cloneDeep(compareData);
    let fmla = equation ? equation.toLowerCase() : '';
    if (equation) {
      if (!temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula) {
        temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].ownerEstimate =
          temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].ownerEstimate;
      } else {
        let f1 = fmla;
        const m = cloneDeep(checkList);
        m?.map((i, l) => {
          i?.specDataList?.map((j, m) => {
            j?.serviceLineList?.map((k, n) => {
              if (k?.number && fmla.includes((k?.number).toLowerCase())) {
                if (fmla.includes((k?.number).toLowerCase() + '.' + 'quantity')) {
                  fmla = fmla.replace(
                    (k?.number).toLowerCase() + '.' + 'quantity',
                    temp[kpfRow.i].sectionQuoteList[l].specList[m].serviceLineList[n].quantity || 0
                  );
                } else if (fmla.includes((k?.number).toLowerCase() + '.' + 'qty')) {
                  fmla = fmla.replace(
                    (k?.number).toLowerCase() + '.' + 'qty',
                    temp[kpfRow.i].sectionQuoteList[l].specList[m].serviceLineList[n].quantity || 0
                  );
                } else if (fmla.includes((k?.number).toLowerCase() + '.' + 'unitprice')) {
                  fmla = fmla.replace(
                    (k?.number).toLowerCase() + '.' + 'unitprice',
                    temp[kpfRow.i].sectionQuoteList[l].specList[m].serviceLineList[n].unitPrice || 0
                  );
                } else if (fmla.includes((k?.number).toLowerCase() + '.' + 'discount')) {
                  fmla = fmla.replace(
                    (k?.number).toLowerCase() + '.' + 'discount',
                    temp[kpfRow.i].sectionQuoteList[l].specList[m].serviceLineList[n].discount || 0
                  );
                } else if (fmla.includes((k?.number).toLowerCase() + '.' + 'yardestimate')) {
                  fmla = fmla.replace(
                    (k?.number).toLowerCase() + '.' + 'yardestimate',
                    temp[kpfRow.i].sectionQuoteList[l].specList[m].serviceLineList[n].yardEstimate || 0
                  );
                } else if (fmla.includes((k?.number).toLowerCase() + '.' + 'ownersestimate')) {
                  fmla = fmla.replace(
                    (k?.number).toLowerCase() + '.' + 'ownersestimate',
                    temp[kpfRow.i].sectionQuoteList[l].specList[m].serviceLineList[n].ownerEstimate || 0
                  );
                }
              }
            });
          });
        });
        let f2 = fmla;
        if (kfpList?.length > 0) {
          kfpList.map((dt) => {
            if (dt?.code && fmla.includes(dt?.code?.toLowerCase())) {
              const t = cloneDeep(checkList);
              t?.map((i, l) => {
                i?.specDataList?.map((j, m) => {
                  j?.serviceLineList?.map((k, n) => {
                    if (k?.number?.toLowerCase() === dt?.code?.toLowerCase()) {
                      fmla = fmla.replace(k?.number?.toLowerCase(), compareData[kpfRow.i].sectionQuoteList[l].specList[m].serviceLineList[n].quantity);
                    }
                  });
                });
              });
            }
          });
        }
        var regExp = /[a-zA-Z]/g;
        const regExNum = /^[0-9+-/*]+$/; // numbers and operators
        const regExSym = /^[a-zA-Z0-9^.*+%\-/()]+$/; // special symbols
        const pattern = /[+\-*.\/]{2}/; // two consecutive operators
        const endsWithOperator = /[\+\-\*\/]$/.test(fmla);
        if (
          (f1 === f2 && f1 === fmla && f2 === fmla && !regExNum.test(equation)) ||
          endsWithOperator ||
          // (f1 !== f2 && f1 !== fmla && f2 === fmla && !regExNum.test(fmla)) ||
          pattern.test(fmla) ||
          !regExSym.test(fmla)
        ) {
          showAlert('Please enter a valid formula', 'error');
        } else {
          if (regExp.test(fmla)) {
            calculateFormula(fmla);
          } else {
            temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].ownerEstimate = evaluate(fmla);
            let num1 = 0;
            if (temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].isFromYard) {
              const row = temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l];
              for (let pos = 0; pos < compareData?.length; pos++) {
                temp[pos].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList?.map((x, inx) => {
                  if (kpfRow.l === inx && !x.isFromYard) {
                    if (row.formula !== '') {
                      x.ownerEstimate = row.ownerEstimate;
                      x.formula = row.formula;
                    }
                  }
                });
              }
            }
            temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList.map((x, indx) => {
              if (x.ownerEstimate) {
                num1 = num1 + toNumber(x.ownerEstimate);
              } else {
                num1 = num1 + toNumber(x.yardEstimate);
              }
              if (temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList.length) {
                temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].ownerEstimate = num1;
              }
            });
            let num2 = 0;
            temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList.map((x, indx) => {
              num2 = num2 + toNumber(x.ownerEstimate);
              if (temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList.length === indx + 1) {
                temp[kpfRow.i].sectionQuoteList[kpfRow.j].ownerEstimate = num2;
              }
            });
            let num3 = 0;
            temp[kpfRow.i].sectionQuoteList.map((x, indx) => {
              num3 = num3 + toNumber(x.ownerEstimate);
              if (temp[kpfRow.i].sectionQuoteList.length === indx + 1) {
                temp[kpfRow.i].ownerEstimate = num3;
              }
            });
            SaveCQ(temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l], compareData?.length > 1 ? true : false);
            setCompareData(temp);
            sortQuotes(temp);
            setCloseModal(false);
            if (!temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].isFromYard && compareData?.length > 1)
              setApplyAllModal({
                status: true,
                pos: { i: kpfRow.i, j: kpfRow.j, k: kpfRow.k, l: kpfRow.l },
                value: evaluate(fmla),
                field: 'ownerEstimate',
                formula: compareData[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula,
                arr: compareData[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l],
              });
          }
        }
      }
    } else {
      temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].ownerEstimate =
        temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].yardEstimate;
      let num1 = 0;
      temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList.map((x, indx) => {
        if (x.ownerEstimate) {
          num1 = num1 + toNumber(x.ownerEstimate);
        } else {
          num1 = num1 + toNumber(x.yardEstimate);
        }
        if (temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList.length) {
          temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].ownerEstimate = num1;
        }
      });
      let num2 = 0;
      temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList.map((x, indx) => {
        num2 = num2 + toNumber(x.ownerEstimate);
        if (temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList.length === indx + 1) {
          temp[kpfRow.i].sectionQuoteList[kpfRow.j].ownerEstimate = num2;
        }
      });
      let num3 = 0;
      temp[kpfRow.i].sectionQuoteList.map((x, indx) => {
        num3 = num3 + toNumber(x.ownerEstimate);
        if (temp[kpfRow.i].sectionQuoteList.length === indx + 1) {
          temp[kpfRow.i].ownerEstimate = num3;
        }
      });
      temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula = '';
      SaveCQ(temp[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l], true);
      setCompareData(temp);
      sortQuotes(temp);
      setCloseModal(false);
    }
  };

  const setContainerClass = (i, index) => {
    const bgContainer = (index + 1) % 2 === 0 ? '' : 'cq-bg-even';
    if (i?.showFull) {
      return 'data-container-cq selected-yard-highlight ms-2 me-2 ' + bgContainer;
    } else if (i?.isSelected) {
      return 'data-container-green-cq ms-2 me-2 ' + bgContainer;
    } else {
      return 'data-container-cq ms-2 me-2 ' + bgContainer;
    }
  };

  const getClass = (index) => {
    const bgColor = (index + 1) % 2 === 0 ? '' : 'cq-bg-even';
    return 'padding-blank ' + bgColor;
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  /* istanbul ignore next */
  const handleScroll = (direction) => {
    const scrollingBox = document.getElementById('scrolling-box');
    const containerWidth = scrollingBox.offsetWidth;
    const scrollWidth = scrollingBox.scrollWidth;
    const maxScrollPosition = scrollWidth - containerWidth;
    const scrollIncrement = containerWidth / 2;

    if (direction === 'right') {
      const newPosition = Math.min(scrollPosition + scrollIncrement, maxScrollPosition);
      setScrollPosition(newPosition);
      scrollingBox.scrollTo(newPosition, 0);
    } else {
      const newPosition = Math.max(scrollPosition - scrollIncrement, 0);
      setScrollPosition(newPosition);
      scrollingBox.scrollTo(newPosition, 0);
    }
  };

  const handleChangeDiscount = (e, i, save, alertValue) => {
    const temp = cloneDeep(compareData);
    if (e.target && e.target.value) {
      const num = e.target.value.match(/^\d{0,3}(\.\d{0,2})?$/) ? e.target.value : temp[i].additionalDiscountPercentage;
      if (num <= 100) {
        temp[i].additionalDiscountPercentage = num.toString();
      }
    } else {
      temp[i].additionalDiscountPercentage = 0;
    }
    const data = {
      rfqId: temp[i].rfqId,
      comment: temp[i].selectionComment,
      isSelected: temp[i].isSelected,
      additionalDiscount: temp[i].additionalDiscount,
      additionalDiscountPercentage: e.target.value ? e.target.value : 0,
    };
    setYardSelect(data);
    if (save) SaveComment(data, alertValue);
    setCompareData(temp);
    sortQuotes(temp);
  };

  return (
    <>
      {closeModal && (
        <ModalBox
          closeModal={() => {
            setCloseModal(false);
            compareData[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula = formula;
          }}
          onSave={() => {
            calculateFormula(compareData[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula);
            if (!compareData[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula)
              compareData[kpfRow.i].sectionQuoteList[kpfRow.j].specList[kpfRow.k].serviceLineList[kpfRow.l].formula = formula;
          }}
          heading="KFP Calculator"
          modalClassName="medium modal-scroll"
          buttonName="Apply"
          className="kfp-modal-box"
        >
          <KFPCalculator
            kpfRow={kpfRow}
            compareData={compareData}
            setCompareData={setCompareData}
            checkList={checkList}
            generalData={generalData}
            kfpList={kfpList}
            searchList={searchList}
            serviceLines={serviceLines}
            setServiceLines={setServiceLines}
          />
        </ModalBox>
      )}
      {addComment && (
        <ModalBox
          modalClassName="medium"
          heading={yardComment ? 'Update Comment' : 'Add Comment'}
          onSave={() => {
            handleChange(commentValue?.comment, commentValue?.index, commentValue?.rfqId, commentValue?.isSelected, true, 'selectionComment', 'save');
            setAddComment(false);
          }}
          closeModal={() => {
            setAddComment(false);
            const temp = {
              comment: '',
              index: '',
              rfqId: '',
              isSelected: '',
            };
            setCommentValue(temp);
          }}
          buttonName={!isTSI || disableFields ? '' : 'Save'}
          className="comment-popup"
        >
          <textarea
            disabled={!isTSI || disableFields ? true : false}
            placeholder="Comment"
            className="text-box w-full h-text-area pt-2"
            data-test="commentField"
            onChange={(e) => {
              setCommentValue({ ...commentValue, comment: e.target.value });
            }}
            value={commentValue?.comment}
          />
        </ModalBox>
      )}
      {selectModal?.status && (
        <ModalBox
          closeModal={() => {
            setSelectModal({ status: false, index: '', data: '' });
          }}
          onSave={() => {
            setSelectModal({ status: false, index: '', data: '' });
            const g = cloneDeep(compareData);
            let count = 0;
            g.map((ii, ind) => {
              if (ind === selectModal?.index) {
                if (ii.isSelected) {
                  ii.isSelected = false;
                } else {
                  ii.isSelected = true;
                  setCanSend(true);
                  setSelectedYard(ii.id);
                  count = count + 1;
                }
              } else {
                ii.isSelected = false;
              }
            });
            if (count === 0) {
              setCanSend(false);
              setSelectedYard('');
            }
            const temp = {
              rfqId: selectModal?.data?.rfqId,
              comment: selectModal?.data?.comment,
              isSelected: !selectModal?.data?.isSelected ? true : false,
              additionalDiscount: selectModal?.data?.additionalDiscount,
              additionalDiscountPercentage: selectModal?.data?.additionalDiscountPercentage
            };
            setYardSelect(temp);
            SaveComment(temp, 'select');
            setCompareData(g);
            sortQuotes(g);
          }}
          heading={selectModal?.data?.isSelected ? 'Deselect' : 'Select'}
          modalClassName="medium modal-scroll"
          buttonName="Confirm"
          className="select-popup"
        >
          <div>Are you sure you want to {selectModal?.data?.isSelected ? 'Deselect' : 'Select'} yard?</div>
        </ModalBox>
      )}
      {applyAllModal?.status && (
        <ModalBox
          modalClassName="small"
          heading="Apply"
          onSave={() => {
            const temp = cloneDeep(compareData);
            let tIndex = applyAllModal?.pos;
            for (let pos = 0; pos < compareData?.length; pos++) {
              temp[pos]?.sectionQuoteList[tIndex.j]?.specList[tIndex.k]?.serviceLineList?.map((x, inx) => {
                if (tIndex.l === inx) {
                  if (applyAllModal?.field === 'quantity') {
                    x.quantity = applyAllModal?.value || 0;
                    const a = x.quantity;
                    const b = x.unitPrice;
                    const c = x.discount;
                    const res = a * b - ((a * b) / 100) * c;
                    x.yardEstimate = res;
                    if (!x.formula && temp[pos]?.sectionQuoteList[tIndex.j]?.specList[tIndex.k].specAssigned === 1) {
                      x.ownerEstimate = res;
                    }
                    if (applyAllModal && applyAllModal.arr && applyAllModal.arr.rfqId !== undefined) {
                      if (x.rfqId === applyAllModal.arr.rfqId) {
                        x.isUpdateQuantity = true;
                        x.isUpdateFormula = false;
                        SaveCQ(x, false);
                      }
                    }
                  } else {
                    x.ownerEstimate = applyAllModal?.value;
                    x.formula = applyAllModal?.formula;
                    if (applyAllModal && applyAllModal.arr && applyAllModal.arr.rfqId !== undefined && x.rfqId === applyAllModal.arr.rfqId) {
                      x.isUpdateFormula = true;
                      x.isUpdateQuantity = false;
                      SaveCQ(x, false);
                    }
                  }
                  let num1 = 0;
                  let oE1 = 0;
                  temp[pos].sectionQuoteList[tIndex.j].specList[tIndex.k].serviceLineList.map((x, xind) => {
                    num1 = num1 + x?.yardEstimate;
                    oE1 = oE1 + x?.ownerEstimate;
                    if (temp[pos].sectionQuoteList[tIndex.j].specList[tIndex.k].serviceLineList.length === xind + 1) {
                      temp[pos].sectionQuoteList[tIndex.j].specList[tIndex.k].yardEstimate = num1;
                      temp[pos].sectionQuoteList[tIndex.j].specList[tIndex.k].ownerEstimate = oE1;
                    }
                  });
                  let num2 = 0;
                  let oE2 = 0;
                  temp[pos].sectionQuoteList[tIndex.j].specList.map((x, xind) => {
                    num2 = num2 + x?.yardEstimate;
                    oE2 = oE2 + x?.ownerEstimate;
                    if (temp[pos].sectionQuoteList[tIndex.j].specList.length === xind + 1) {
                      temp[pos].sectionQuoteList[tIndex.j].yardEstimate = num2;
                      temp[pos].sectionQuoteList[tIndex.j].ownerEstimate = oE2;
                    }
                  });
                  let num3 = 0;
                  let oE3 = 0;
                  temp[pos].sectionQuoteList.map((x, xind) => {
                    num3 = num3 + x?.yardEstimate;
                    oE3 = oE3 + x?.ownerEstimate;
                    if (temp[pos].sectionQuoteList.length === xind + 1) {
                      temp[pos].yardTotal = num3;
                      temp[pos].ownerEstimate = oE3;
                    }
                  });
                }
              });
            }
            temp[tIndex.i].sectionQuoteList[tIndex.j].specList[tIndex.k].serviceLineList[tIndex.l]['editQuantity'] = false;
            setCompareData(temp);
            sortQuotes(temp);
            setApplyAllModal({
              status: false,
              pos: { i: '', j: '', k: '', l: '' },
              value: '',
              field: '',
              formula: '',
              arr: '',
            });
          }}
          closeModal={() => {
            let tIndex = applyAllModal?.pos;
            const j = cloneDeep(compareData);
            j[tIndex.i].sectionQuoteList[tIndex.j].specList[tIndex.k].serviceLineList[tIndex.l]['editQuantity'] = false;
            setCompareData(j);
            sortQuotes(j);
            reload(j);
            setApplyAllModal({
              status: false,
              pos: { i: '', j: '', k: '', l: '' },
              value: '',
              field: '',
              formula: '',
              arr: '',
            });
          }}
          isNoLabel={true}
          buttonName="Yes"
          className="applyall-popup"
        >
          <div>Do you want to apply the value for all yards?</div>
        </ModalBox>
      )}
      <CostCalculator
        showSideBarCost={showSideBarCost}
        onClose={onClose}
        sidePanelData={sidePanelData}
        setSidePanelData={setSidePanelData}
        postDOCostDetails={postDOCostDetails}
        compareData={compareData}
        setCompareData={setCompareData}
        disableFields={disableFields}
        sortQuotes={sortQuotes}
      />
      <CQSidePanel commentClicked={commentClicked} showSideBar={showSideBar} onClose={onClose} docData={docData} />
      {compareData?.length > 1 && (
        <button type="button" id="excel" className="link-btn large left-icon-cq" data-test="scroll1" onClick={() => handleScroll('left')}>
          <span className="icon-arrow-thin-left font-22" />
        </button>
      )}
      <div id="scrolling-box" className="scrolls-cq ms-2 text-xl p-2">
        {compareData?.map((i, index) => {
          const bgRow = (index + 1) % 2 === 0 ? '' : 'cq-bg-even';
          i?.sectionQuoteList?.map((j) => {
            j?.specList?.length > 0 &&
              j?.specList?.map((f) => {
                f?.serviceLineList?.length > 0 &&
                  f?.serviceLineList?.map((g) => {
                    if (g?.comments && g?.isFromYard) {
                      f.commentExist = true;
                      j.commentExist = true;
                    }
                  });
              });
          });
          let classToBeAdded = '';
          let classToBeAddedd = 'ribbon ribbon-top-left'
          if (compareData?.length > 1) {
            sortedQuotes?.map((quote) => {
              if (quote.id === i.id) {
                if (quote?.isHighest) {
                  classToBeAdded = 'Highest';
                  classToBeAddedd = 'Highest ribbon ribbon-top-left';
                } else if (quote?.isMedian) {
                  classToBeAdded = 'Average';
                  classToBeAddedd = 'Average ribbon ribbon-top-left';
                } else if (quote?.isLowest) {
                  classToBeAdded = 'Lowest';
                  classToBeAddedd = 'Lowest ribbon ribbon-top-left';
                } else {
                  classToBeAdded = '';
                }
              }
            });
          }
          return (
            <div key={index} className={setContainerClass(i, index)}>
              <div>
                <div className="border-b ">
                  <div className="d-flex mt-2">
                    <div
                      className={
                        i?.showFull
                          ? 'col-9 d-flex ms-2 text-lg font-bold p-3 capitalize timeline-text-wrap w-yardHeader-full'
                          : 'col-9 d-flex ms-2 text-lg font-bold p-3 capitalize timeline-text-wrap w-yardHeader'
                      }
                    >
                      {classToBeAdded && (
                        <div className={classToBeAddedd}><span>{classToBeAdded}</span></div>
                      )}
                      <span className="z-index-120" data-tooltip-id="yardName" data-tooltip-content={i?.yardName}>
                        {classToBeAdded
                          ? <span className="badge-yardName w-full">{i?.yardName}</span>
                          : i?.yardName
                        }
                      </span>
                      <Tooltip id="yardName" place="top" className="tooltip mt-3" />
                    </div>
                    <span
                      className="ms-auto cursor-pointer p-3"
                      data-test="click1"
                      onClick={() => {
                        setCompareData(prevData =>
                          prevData.map((k, l) => ({
                            ...k,
                            showFull: index === l ? !k.showFull : false,
                            showCommentBox: index === l && k.showFull ? false : k.showCommentBox
                          }))
                        );
                      }}
                    >
                      {i?.showFull ? (
                        <img className="icon-md-cq me-2 br-icon" src={alertLeft} alt="flag" />
                      ) : (
                        <img className="icon-md-cq me-2 br-icon" src={arrowRight} alt="flag" />
                      )}
                    </span>
                  </div>
                </div>
                <div className="p-3 text-lg mt-6p">
                  <div className="ms-2 d-flex">
                    <div className="width-yard-headers">TRD</div>
                    <div className="ms-2 font-dim">:&nbsp;{i?.trd + ' Days'}</div>
                  </div>
                  <div className="ms-2 d-flex">
                    <div className="width-yard-headers">Days In Dock</div>
                    <div className="ms-2 font-dim">:&nbsp;{i?.inDockDays + ' Days'}</div>
                  </div>
                  <div className="ms-2 d-flex">
                    <div className="width-yard-headers">Days Afloat</div>
                    <div className="ms-2 font-dim">:&nbsp;{i?.daysAfloat + ' Days'}</div>
                  </div>
                  <div className="ms-2 d-flex">
                    <div className="width-yard-headers">Payment Terms</div>
                    <div
                      className={i?.showFull ? 'ms-2 font-dim timeline-text-wrap w-yardcomment' : 'ms-2 font-dim timeline-text-wrap w-8'}
                      data-tooltip-id="project-mapDatacontent"
                      data-tooltip-content={i?.paymentTerms}
                    >
                      <span>:&nbsp;{i?.paymentTerms}</span>
                    </div>
                    <Tooltip id="project-mapDatacontent" place="top" className="tooltip mt-3" />
                  </div>
                  <div className="ms-2 d-flex">
                    <div className="width-yard-headers">Yard Comments</div>
                    <div className={i?.showFull ? 'ms-2 font-dim timeline-text-wrap w-yardcomment' : 'ms-2 font-dim timeline-text-wrap w-8'}>
                      <span data-tooltip-id="project-mapData" data-tooltip-content={i?.comment}>
                        :&nbsp;{i?.comment}
                      </span>
                    </div>
                    <Tooltip id="project-mapData" place="top" className="tooltip mt-3" />
                  </div>
                  <div className="ms-2 d-flex">
                    <div className="width-yard-headers">Deviation & Off Hire</div>
                    <div className={i?.showFull ? 'ms-2 font-dim' : 'ms-2 font-dim timeline-text-wrap w-8'}>
                      <span
                        data-tooltip-id="project-mapData"
                        data-tooltip-content={generalData?.currencySymbol + ' ' + formatNumber(toNumber(i?.deviationCost) + toNumber(i?.offhireCost))}
                      >
                        :&nbsp;{generalData?.currencySymbol + ' ' + formatNumber(toNumber(i?.deviationCost) + toNumber(i?.offhireCost))}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <table className="compare-quote-table">
                <tbody>
                  <tr className="table-header-cq">
                    {i?.showFull && (
                      <th>
                        <div className="me-2">Unit</div>
                      </th>
                    )}
                    {i?.showFull && (
                      <th>
                        <div className="me-2">Qty</div>
                      </th>
                    )}
                    {i?.showFull && (
                      <th>
                        <div className="me-2">
                          <div>Unit</div>
                          <div>Price</div>
                        </div>
                      </th>
                    )}
                    {i?.showFull && (
                      <th>
                        <div className="me-2">Discount %</div>
                      </th>
                    )}
                    <th>
                      <div className="me-1">
                        <div>Yard</div>
                        <div>Cost Estimate</div>
                      </div>
                    </th>
                    <th>
                      <div className="me-1">
                        <div>Yard</div>
                        <div>Estimate</div>
                      </div>
                    </th>
                    <th>
                      <div className="me-1">
                        <div>Owner's</div>
                        <div>Cost</div>
                      </div>
                    </th>
                  </tr>
                  {i?.sectionQuoteList?.map((j, ind) => {
                    return (
                      <>
                        {ind === specCount && (
                          <tr>
                            {i?.showFull && <td className="padding-non-spec"></td>}
                            {i?.showFull && <td className="padding-non-spec"></td>}
                            {i?.showFull && <td className="padding-non-spec"></td>}
                            {i?.showFull && <td className="padding-non-spec"></td>}
                            <td className="padding-non-spec"></td>
                            <td className="padding-non-spec"></td>
                            <td className="padding-non-spec"></td>
                          </tr>
                        )}
                        <tr key={ind} className={j.number === 'DEF' ? 'd-none' : ''}>
                          {!j?.show ? i?.showFull && <td></td> : i?.showFull && <td></td>}
                          {!j?.show ? i?.showFull && <td></td> : i?.showFull && <td></td>}
                          {!j?.show ? i?.showFull && <td></td> : i?.showFull && <td></td>}
                          {!j?.show ? i?.showFull && <td></td> : i?.showFull && <td></td>}
                          {!j?.show ? (
                            <td className="timeline-text-wrap">
                              <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(j?.yardEstimate)}>
                                {formatNumber(j?.yardEstimate)}
                              </span>
                            </td>
                          ) : (
                            <td className="timeline-text-wrap font-bold">
                              <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(j?.yardEstimate)}>
                                {formatNumber(j?.yardEstimate)}
                              </span>
                            </td>
                          )}
                          {!j?.show ? (
                            <td className="timeline-text-wrap">
                              <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(j?.ownerEstimate)}>
                                {formatNumber(j?.ownerEstimate)}
                              </span>
                            </td>
                          ) : (
                            <td className="timeline-text-wrap font-bold">
                              <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(j?.ownerEstimate)}>
                                {formatNumber(j?.ownerEstimate)}
                              </span>
                            </td>
                          )}
                          {!j?.show ? (
                            <td
                              className={j?.commentExist ? 'timeline-text-wrap d-flex justify-content-end' : 'timeline-text-wrap justify-content-end'}
                            >
                              {j?.commentExist && (
                                <button
                                  data-tooltip-id="project-specNamed"
                                  data-tooltip-content="Yard commented in service line"
                                  type="button"
                                  className="d-flex link-btn large me-auto w-3"
                                >
                                  <span className="icon-message-square font-20" />
                                  <span className="dot-indicator ms-3" />
                                </button>
                              )}
                              <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(j?.ownerCost)}>
                                {formatNumber(j?.ownerCost)}
                              </span>
                            </td>
                          ) : (
                            <td className="timeline-text-wrap font-bold">
                              <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(j?.ownerCost)}>
                                {formatNumber(j?.ownerCost)}
                              </span>
                            </td>
                          )}
                          <Tooltip id="project-specNamed" place="top" className="tooltip mt-3" />
                        </tr>
                        {j?.show &&
                          j?.specList?.map((x, s) => {
                            return (
                              <>
                                <tr key={s}>
                                  {!x?.show ? i?.showFull && <td></td> : i?.showFull && <td></td>}
                                  {!x?.show ? i?.showFull && <td></td> : i?.showFull && <td></td>}
                                  {!x?.show ? i?.showFull && <td></td> : i?.showFull && <td></td>}
                                  {!x?.show ? i?.showFull && <td></td> : i?.showFull && <td></td>}
                                  {!x?.show ? (
                                    <td className="timeline-text-wrap">{formatNumber(x?.yardEstimate)}</td>
                                  ) : (
                                    <td className="timeline-text-wrap font-bold">{formatNumber(x?.yardEstimate)}</td>
                                  )}
                                  {!x?.show ? (
                                    <td
                                      className="timeline-text-wrap"
                                      data-tooltip-id="project-specNamed"
                                      data-tooltip-content={x?.specAssigned === 1 ? formatNumber(x?.ownerEstimate) : formatNumber(0)}
                                    >
                                      {x?.specAssigned === 1 ? formatNumber(x?.ownerEstimate) : formatNumber(0)}
                                    </td>
                                  ) : (
                                    <td
                                      className="timeline-text-wrap font-bold"
                                      data-tooltip-id="project-specNamed"
                                      data-tooltip-content={formatNumber(x?.ownerEstimate)}
                                    >
                                      {x?.specAssigned === 1 ? formatNumber(x?.ownerEstimate) : formatNumber(0)}
                                    </td>
                                  )}
                                  {!x?.show ? (
                                    <td>
                                      <div className="timeline-text-wrap d-flex justify-content-end">
                                        {x?.commentExist && (
                                          <button
                                            data-tooltip-id="project-specNamed"
                                            data-tooltip-content="Yard commented in service line"
                                            type="button"
                                            className="d-flex link-btn large me-auto w-3"
                                          >
                                            <span className="icon-message-square font-20" />
                                            <span className="dot-indicator ms-3" />
                                          </button>
                                        )}
                                        {formatNumber(x?.ownerCost)}
                                      </div>
                                    </td>
                                  ) : (
                                    <td>
                                      <div className={x?.hasSpecDocuments ? 'd-flex font-bold' : 'ms-auto font-bold'}>
                                        {x?.hasSpecDocuments && x.specDocuments && (
                                          <img
                                            className="cursor-pointer icon-sm me-auto br-icon"
                                            src={attachment}
                                            alt="flag"
                                            data-test="imgClick1"
                                            onClick={() => {
                                              setDocData(x.specDocuments);
                                              setShowSideBar(true);
                                              setCommentClicked(false);
                                            }}
                                          />
                                        )}
                                        {formatNumber(x?.ownerCost)}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                                {x?.show &&
                                  x?.serviceLineList &&
                                  x?.serviceLineList?.map((r, t) => {
                                    const disableOE =
                                      checkList[ind].specDataList[s].specAssigned === 2 || checkList[ind].specDataList[s].specAssigned === 3
                                        ? true
                                        : false;
                                    return (
                                      <>
                                        <tr key={t} style={{ background: r?.color ? r?.color : '' }}>
                                          {i?.showFull ? (
                                            !r?.editUnit ? (
                                              <td
                                                className={
                                                  !disableFields && r?.id !== '00000000-0000-0000-0000-000000000000' && r?.specificationLevel !== 4
                                                    ? 'cursor-pointer max-w-8 timeline-text-wrap cell-left-align-cq'
                                                    : 'max-w-8 timeline-text-wrap cell-left-align-cq'
                                                }
                                                data-tooltip-id="project-specNamed"
                                                data-tooltip-content={r?.uomName ? r?.uomName : ''}
                                                data-test="newClick1"
                                                onClick={() => {
                                                  if (
                                                    isTSI &&
                                                    !disableFields &&
                                                    r?.id !== '00000000-0000-0000-0000-000000000000' &&
                                                    r?.specificationLevel !== 4
                                                  ) {
                                                    setCompareData(prevData => {
                                                      prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t] = {
                                                        ...prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t],
                                                        editUnit: true,
                                                        oldUnitId: r?.uomId
                                                      };
                                                      return [...prevData];
                                                    });
                                                  }
                                                }}
                                              >
                                                {r?.uomName}
                                              </td>
                                            ) : (
                                              <td>
                                                <select
                                                  className="select small active w-9"
                                                  data-test="change1"
                                                  value={r.uomId}
                                                  autoFocus
                                                  onChange={(option) => handleChangeRow(option, index, ind, s, t, 'uomId', false, 'select')}
                                                  onBlur={(e) => {
                                                    if (r?.oldUnitId !== e.target.value) {
                                                      handleChangeRow(e, index, ind, s, t, 'uomId', true, 'select');
                                                    }
                                                    setCompareData(prevData => {
                                                      prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t] = {
                                                        ...prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t],
                                                        editUnit: false
                                                      };
                                                      return [...prevData];
                                                    });
                                                  }}
                                                >
                                                  {units.map((m) => (
                                                    <option key={m} value={m.value}>
                                                      {m.label}
                                                    </option>
                                                  ))}
                                                </select>
                                              </td>
                                            )
                                          ) : (
                                            <td className="display-none"></td>
                                          )}
                                          {i?.showFull ? (
                                            !r?.editQuantity ? (
                                              <td
                                                className={!disableFields && r?.id !== '00000000-0000-0000-0000-000000000000' ? 'cursor-pointer' : ''}
                                                data-test="newClick2"
                                                onClick={() => {
                                                  if (isTSI && !disableFields && r?.id !== '00000000-0000-0000-0000-000000000000') {
                                                    setCompareData(prevData => {
                                                      prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t] = {
                                                        ...prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t],
                                                        editQuantity: true,
                                                        oldQuantity: r?.quantity
                                                      };
                                                      return [...prevData];
                                                    });
                                                  }
                                                }}
                                              >
                                                {r?.quantity}
                                              </td>
                                            ) : (
                                              <td>
                                                <input
                                                  type="numeric"
                                                  className={
                                                    i?.showFull
                                                      ? 'cursor-pointer text-box small compare-quote-input'
                                                      : 'cursor-not-allowed text-box small compare-quote-input'
                                                  }
                                                  value={r?.quantity ? r?.quantity : ''}
                                                  data-test="change2"
                                                  autoFocus
                                                  onChange={(e) => {
                                                    handleChangeRow(e, index, ind, s, t, 'quantity', false, '');
                                                  }}
                                                    onBlur={(e) => {
                                                      setCompareData(prevData => {
                                                        const updatedData = [...prevData];
                                                        if (toNumber(r?.oldQuantity) !== toNumber(e.target.value)) {
                                                          handleChangeRow(e, index, ind, s, t, 'quantity', true, '');
                                                          const currentServiceLine = updatedData[index].sectionQuoteList[ind].specList[s].serviceLineList[t];
                                                          if (!currentServiceLine.isFromYard && prevData.length > 1) {
                                                            setApplyAllModal({
                                                              status: true,
                                                              pos: { i: index, j: ind, k: s, l: t },
                                                              value: e.target.value,
                                                              field: 'quantity',
                                                              formula: '',
                                                              arr: currentServiceLine
                                                            });
                                                          } else {
                                                            const row = { ...currentServiceLine, quantity: e.target.value };
                                                            for (let pos = 0; pos < prevData.length; pos++) {
                                                              updatedData[pos].sectionQuoteList[ind].specList[s].serviceLineList =
                                                                updatedData[pos].sectionQuoteList[ind].specList[s].serviceLineList.map((x, inx) =>
                                                                  t === inx ? { ...x, quantity: row.quantity } : x
                                                                );
                                                            }
                                                          }
                                                        }
                                                        updatedData[index].sectionQuoteList[ind].specList[s].serviceLineList[t] = {
                                                          ...updatedData[index].sectionQuoteList[ind].specList[s].serviceLineList[t],
                                                          editQuantity: false
                                                        };

                                                        return updatedData;
                                                      });
                                                    }}
                                                  />
                                              </td>
                                            )
                                          ) : (
                                            <td className="display-none"></td>
                                          )}
                                          {i?.showFull ? (
                                            !r?.editUnitPrice ? (
                                              <td
                                                className={
                                                  !disableFields && r?.id !== '00000000-0000-0000-0000-000000000000' && r?.specificationLevel !== 4
                                                    ? 'cursor-pointer'
                                                    : ''
                                                }
                                                data-test="newClick3"
                                                onClick={() => {
                                                  if (
                                                    isTSI &&
                                                    !disableFields &&
                                                    r?.id !== '00000000-0000-0000-0000-000000000000' &&
                                                    r?.specificationLevel !== 4
                                                  ) {
                                                    setCompareData(prevData => {
                                                      prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t] = {
                                                        ...prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t],
                                                        editUnitPrice: true,
                                                        oldUnitPrice: r?.unitPrice
                                                      };
                                                      return [...prevData];
                                                    });
                                                  }
                                                }}
                                              >
                                                {formatNumber(r?.unitPrice)}
                                              </td>
                                            ) : (
                                              <td>
                                                <input
                                                  type="numeric"
                                                  className={
                                                    i?.showFull
                                                      ? 'cursor-pointer text-box small compare-quote-input'
                                                      : 'cursor-not-allowed text-box small compare-quote-input'
                                                  }
                                                  value={r?.unitPrice ? r?.unitPrice : ''}
                                                  autoFocus
                                                  data-test="change3"
                                                  onChange={(e) => {
                                                    handleChangeRow(e, index, ind, s, t, 'unitPrice', false, '');
                                                  }}
                                                  onBlur={(e) => {
                                                    if (toNumber(r.oldUnitPrice) !== toNumber(e.target.value)) {
                                                      handleChangeRow(e, index, ind, s, t, 'unitPrice', true, '');
                                                    }
                                                    setCompareData(prevData => {
                                                      prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t] = {
                                                        ...prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t],
                                                        editUnitPrice: false
                                                      };
                                                      return [...prevData];
                                                    });
                                                  }}
                                                />
                                              </td>
                                            )
                                          ) : (
                                            <td className="display-none"></td>
                                          )}
                                          {i?.showFull ? (
                                            !r?.editDiscount ? (
                                              <td
                                                className={
                                                  !disableFields && r?.id !== '00000000-0000-0000-0000-000000000000' && r?.specificationLevel !== 4
                                                    ? 'cursor-pointer'
                                                    : ''
                                                }
                                                data-test="newClick4"
                                                onClick={() => {
                                                  if (
                                                    isTSI &&
                                                    !disableFields &&
                                                    r?.id !== '00000000-0000-0000-0000-000000000000' &&
                                                    r?.specificationLevel !== 4
                                                  ) {
                                                    setCompareData(prevData => {
                                                      prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t] = {
                                                        ...prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t],
                                                        editDiscount: true,
                                                        oldDiscount: r?.discount
                                                      };
                                                      return [...prevData];
                                                    });
                                                  }
                                                }}
                                              >
                                                {r?.discount ? formatNumber(r?.discount) : 0}
                                              </td>
                                            ) : (
                                              <td>
                                                <input
                                                  type="text"
                                                  className={
                                                    i?.showFull
                                                      ? 'cursor-pointer text-box small compare-quote-input'
                                                      : 'cursor-not-allowed text-box small compare-quote-input'
                                                  }
                                                  value={r?.discount ? r?.discount : ''}
                                                  autoFocus
                                                  data-test="change4"
                                                  onChange={(e) => {
                                                    handleChangeRow(e, index, ind, s, t, 'discount', false, '');
                                                  }}
                                                  onBlur={(e) => {
                                                    if (toNumber(r.oldDiscount) !== toNumber(e.target.value)) {
                                                      handleChangeRow(e, index, ind, s, t, 'discount', true, '');
                                                    }
                                                    setCompareData(prevData => {
                                                      prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t] = {
                                                        ...prevData[index].sectionQuoteList[ind].specList[s].serviceLineList[t],
                                                        editDiscount: false
                                                      };
                                                      return [...prevData];
                                                    });
                                                  }}
                                                />
                                              </td>
                                            )
                                          ) : (
                                            <td className="display-none"></td>
                                          )}
                                          <td
                                            data-tooltip-id="project-specNamed"
                                            data-tooltip-content={formatNumber(r?.yardEstimate)}
                                            className={
                                              r?.isFromYard && r?.yardEstimate > 0
                                                ? 'font-yardQuotedEstimate font-bold'
                                                : r?.id === '00000000-0000-0000-0000-000000000000' && r?.yardEstimate > 0
                                                  ? 'font-yardEstimate font-bold'
                                                  : ''
                                            }
                                          >
                                            {formatNumber(r?.yardEstimate)}
                                          </td>
                                          {x?.number && isTSI && i?.showFull && r?.id !== '00000000-0000-0000-0000-000000000000' ? (
                                            <td
                                              className="padding-input"
                                              data-tooltip-id="project-specNamed"
                                              data-tooltip-content={r?.formula ? r?.formula : ''}
                                            >
                                              <input
                                                type="numeric"
                                                className={
                                                  i?.showFull && !disableOE && !disableFields && r?.id !== '00000000-0000-0000-0000-000000000000'
                                                    ? 'cursor-pointer text-box small compare-quote-input'
                                                    : 'text-box small compare-quote-input'
                                                }
                                                value={
                                                  r?.formula
                                                    ? formatNumber(r?.ownerEstimate)
                                                    : x?.specAssigned === 1
                                                      ? formatNumber(r?.ownerEstimate)
                                                      : formatNumber(0)
                                                }
                                                disabled={i?.showFull && isTSI && !disableOE && !disableFields ? false : true}
                                                data-test="click4"
                                                onClick={() => {
                                                  setFormula(r?.formula);
                                                  setKPFRow({ i: index, j: ind, k: s, l: t });
                                                  setCloseModal(true);
                                                }}
                                              />
                                            </td>
                                          ) : (
                                            <td
                                              className="timeline-text-wrap"
                                              data-tooltip-id="project-specNamed"
                                              data-tooltip-content={r?.formula ? r?.formula : x?.specAssigned === 1 ? r?.formula : formatNumber(0)}
                                            >
                                              {r?.formula
                                                ? formatNumber(r?.ownerEstimate)
                                                : x?.specAssigned === 1
                                                  ? formatNumber(r?.ownerEstimate)
                                                  : formatNumber(0)}
                                            </td>
                                          )}
                                          <td>
                                            {r.comments && (
                                              <button
                                                type="button"
                                                className="d-flex link-btn large ms-auto me-2"
                                                data-test="imgClick2"
                                                onClick={() => {
                                                  setDocData(r.comments);
                                                  setShowSideBar(true);
                                                  setCommentClicked(true);
                                                }}
                                              >
                                                <span className="icon-message-square font-16" />
                                              </button>
                                            )}
                                          </td>
                                        </tr>
                                        {x?.serviceLineList?.length === t + 1 && (
                                          <tr className={bgRow ? bgRow : 'bg-newsl'}>
                                            {i?.showFull && <td className="p-newserviceline"></td>}
                                            {i?.showFull && <td className="p-newserviceline"></td>}
                                            {i?.showFull && <td className="p-newserviceline"></td>}
                                            {i?.showFull && <td className="p-newserviceline"></td>}
                                            <td className="p-newserviceline"></td>
                                            <td className="p-newserviceline"></td>
                                            <td className="p-newserviceline"></td>
                                          </tr>
                                        )}
                                      </>
                                    );
                                  })}
                              </>
                            );
                          })}
                      </>
                    );
                  })}
                </tbody>
              </table>
              <div className="font-navy bg-gray-cq">
                <div className="horizontal-line-main">
                  <div className="padding-blank bg-gray-cq"></div>
                </div>
                <div className="cost-details">
                  <div className="ms-auto w-auto">{formatNumber(round(i?.ownerEstimate))}</div>
                </div>
                <div className="cost-details">
                  <div className="ms-auto w-auto">
                    {isTSI && i?.showFull ? (
                      <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(i?.additionalDiscountPercentage)}>
                        <input
                          type="text"
                          disabled={!isTSI || disableFields}
                          data-test="additionalDiscountPercentage"
                          className={
                            i?.showFull
                              ? 'cursor-pointer text-box small compare-quote-input'
                              : 'cursor-not-allowed text-box small compare-quote-input'
                          }
                          value={i?.additionalDiscountPercentage ? i?.additionalDiscountPercentage : ''}
                          onClick={() => (i.oldAdditionalDiscountPercentage = i?.additionalDiscountPercentage)}
                          onChange={(e) => {
                            handleChangeDiscount(e, index, false, 'save');
                          }}
                          onBlur={(e) => {
                            if (toNumber(i.oldAdditionalDiscountPercentage) !== toNumber(e.target.value)) {
                              handleChangeDiscount(e, index, true, 'save');
                            }
                          }}
                        />
                      </span>
                    ) : (
                      <div>{formatNumber(round(i?.additionalDiscountPercentage))}</div>
                    )}
                  </div>
                </div>
                <div className="cost-details">
                  <div className="ms-auto w-auto">
                    {isTSI && i?.showFull ? (
                      <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(round(i?.additionalDiscount))}>
                        <input
                          type="text"
                          disabled={!isTSI || disableFields}
                          data-test="additionalDiscount"
                          className={
                            i?.showFull
                              ? 'cursor-pointer text-box small compare-quote-input'
                              : 'cursor-not-allowed text-box small compare-quote-input'
                          }
                          value={i?.additionalDiscount ? i?.additionalDiscount : ''}
                          onClick={() => (i.oldAdditionalDiscount = i?.additionalDiscount)}
                          onChange={(e) => {
                            handleChange(e, index, i.rfqId, i.isSelected, false, 'additionalDiscount', 'save');
                          }}
                          onBlur={(e) => {
                            if (toNumber(i.oldAdditionalDiscount) !== toNumber(e.target.value)) {
                              handleChange(e, index, i.rfqId, i.isSelected, true, 'additionalDiscount', 'save');
                            }
                          }}
                        />
                      </span>
                    ) : (
                      <div>{formatNumber(round(i?.additionalDiscount))}</div>
                    )}
                  </div>
                </div>
                <div className="cost-details">
                  <div className="ms-auto w-auto">
                    {formatNumber(
                      round(i.ownerEstimate) -
                      round((i.ownerEstimate / 100) * toNumber(i.additionalDiscountPercentage)) -
                      round(i?.additionalDiscount)
                    )}
                  </div>
                </div>
                <div className="horizontal-line-main">
                  <div className={getClass(index)}></div>
                </div>
                <div className="cost-details">
                  <div className="ms-auto w-auto">{formatNumber(round(i?.ownerCost))}</div>
                </div>
                <div className="horizontal-line-main">
                  <div className={getClass(index)}></div>
                </div>
                <div className="cost-details">
                  <div className="ms-auto w-auto">
                    <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(round(i?.deviationCost))}>
                      <input
                        type="text"
                        data-test="cost1"
                        className={
                          i?.showFull
                            ? 'cursor-pointer text-box small compare-quote-input'
                            : 'cursor-not-allowed text-box small compare-quote-input'
                        }
                        value={formatNumber(round(i?.deviationCost))}
                        onClick={() => {
                          const temp = {
                            rfqId: i.rfqId,
                            repairDays: toNumber(i?.trd),
                            deviationDays: i?.deviationDays,
                            hireRate: i?.hireRate,
                            fuelPriceType1: i?.fuelPriceType1,
                            fuelType1Consumption: i?.fuelType1Consumption,
                            fuelPriceType2: i?.fuelPriceType2,
                            fuelType2Consumption: i?.fuelType2Consumption,
                            miscCost: i?.miscCost,
                            offHireCost: i?.offhireCost,
                            fuelCost: i?.fuelCost,
                            deviationCost: i?.deviationCost,
                            daysAfloat: i?.daysAfloat,
                            inDockDays: i?.inDockDays,
                            fuelType1Id: i?.fuelType1Id,
                            fuelType2Id: i?.fuelType2Id,
                          };
                          setSidePanelData(temp);
                          setShowSideBarCost(true);
                        }}
                      />
                    </span>

                  </div>
                </div>
                <div className="cost-details">
                  <div className="ms-auto w-auto">
                    <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(round(i?.offhireCost))}>
                      <input
                        type="text"
                        data-test="cost2"
                        className={
                          i?.showFull
                            ? 'cursor-pointer text-box small compare-quote-input'
                            : 'cursor-not-allowed text-box small compare-quote-input'
                        }
                        value={formatNumber(round(i?.offhireCost))}
                        onClick={() => {
                          const temp = {
                            rfqId: i.rfqId,
                            repairDays: toNumber(i?.trd),
                            deviationDays: i?.deviationDays,
                            hireRate: i?.hireRate,
                            fuelPriceType1: i?.fuelPriceType1,
                            fuelType1Consumption: i?.fuelType1Consumption,
                            fuelPriceType2: i?.fuelPriceType2,
                            fuelType2Consumption: i?.fuelType2Consumption,
                            miscCost: i?.miscCost,
                            offHireCost: i?.offhireCost,
                            fuelCost: i?.fuelCost,
                            deviationCost: i?.deviationCost,
                            daysAfloat: i?.daysAfloat,
                            inDockDays: i?.inDockDays,
                            fuelType1Id: i?.fuelType1Id,
                            fuelType2Id: i?.fuelType2Id,
                          };
                          setSidePanelData(temp);
                          setShowSideBarCost(true);
                        }}
                      />
                    </span>

                  </div>
                </div>
                <div className="cost-details">
                  <div className="ms-auto w-auto">{formatNumber(round(i?.deviationCost) + round(i?.offhireCost))}</div>
                </div>
                <div className="horizontal-line-main">
                  <div className={getClass(index)}></div>
                </div>
                <div className="cost-details text-xl font-bold">
                  <div className="ms-auto timeline-text-wrap">
                    {formatNumber(
                      round(i.ownerEstimate) -
                      round((i.ownerEstimate / 100) * toNumber(i.additionalDiscountPercentage)) -
                      round(i?.additionalDiscount) +
                      round(i?.ownerCost)
                    )}
                  </div>
                </div>
                <div className="cost-details text-xl font-bold">
                  <div className="ms-auto timeline-text-wrap">
                    {formatNumber(
                      round(i.ownerEstimate) -
                      round((i.ownerEstimate / 100) * toNumber(i.additionalDiscountPercentage)) -
                      round(i?.additionalDiscount) +
                      round(i?.ownerCost) +
                      round(i?.deviationCost) +
                      round(i?.offhireCost)
                    )}
                  </div>
                </div>
              </div>
              <div
                data-tooltip-id="project-specNamed"
                data-tooltip-content={i?.showFull ? '' : 'Expand to add/view comment'}
                className={i?.showFull ? 'd-flex align-items-center ms-3 p-2 mt-1 cursor-pointer' : 'd-flex align-items-center ms-3 p-2 mt-1'}
                data-test="commentClick"
                onClick={() => {
                  if (i?.showFull) {
                    setAddComment(true);
                    setYardComment(i.selectionComment);
                    const temp = {
                      comment: i.selectionComment,
                      index: index,
                      rfqId: i.rfqId,
                      isSelected: i.isSelected,
                    };
                    setCommentValue(temp);
                  }
                }}
              >
                <img className="icon-sm ms-2" src={plusCircle} alt="flag" data-test="click2" />
                <div>
                  <div
                    className="font-darkblue font-semibold ms-2 me-4"
                    data-tooltip-id="project-specNamed"
                    data-tooltip-content={i?.showFull ? '' : 'Expand to add/view comment'}
                  />
                  <div className="ms-2 font-13">{!i?.selectionComment ? 'Add Comment' : 'View Comment'}</div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3 mb-2">
                <button
                  className={
                    isTSI && !repairStarted && (!disableFields || projectDetails?.tenderApprovalStatus === 2)
                      ? i?.isSelected
                        ? 'secondary-btn large btn-success'
                        : canSend
                          ? 'secondary-btn disabled large'
                          : 'secondary-btn large'
                      : i?.isSelected
                        ? 'secondary-btn large disabled btn-success'
                        : 'secondary-btn large disabled'
                  }
                  data-test="buttonclick2"
                  onClick={() => {
                    if (isTSI && !repairStarted && (i?.isSelected || !canSend) && (!disableFields || projectDetails?.tenderApprovalStatus === 2)) {
                      setSelectModal({ status: true, index: index, data: i });
                    }
                  }}
                >
                  {i?.isSelected ? 'Deselect' : 'Select Yard'}
                </button>
              </div>
            </div>
          );
        })}
        <Tooltip id="project-specNamed" place="top" className="tooltip mt-3" />
      </div>
      {compareData?.length > 1 && (
        <button type="button" id="excel" className="link-btn large right-icon-cq" data-test="scroll2" onClick={() => handleScroll('right')}>
          <span className="icon-arrow-thin-right font-22" />
        </button>
      )}
    </>
  );
};

export default MappingData;
